
p img {
  
	display: inline-block;
  
	vertical-align: middle;
  
	width: 100%;

  	border-radius: 7px;

}


.article-page :where(ol):not(:where([class~=not-article-page],[class~=not-article-page] *)) {
    list-style-type: decimal;
    margin-bottom: 2rem;
    margin-top: 0;
    padding-inline-start:1.625em}

.article-page :where(ol[type=A]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.article-page :where(ol[type=a]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.article-page :where(ol[type=A s]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.article-page :where(ol[type=a s]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.article-page :where(ol[type=I]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: upper-roman
}

.article-page :where(ol[type=i]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: lower-roman
}

.article-page :where(ol[type=I s]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: upper-roman
}

.article-page :where(ol[type=i s]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: lower-roman
}

.article-page :where(ol[type="1"]):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: decimal
}

.article-page :where(ul):not(:where([class~=not-prose],[class~=not-prose] *)) {
    list-style-type: disc;
    margin-bottom: 2rem;
    margin-top: 0;
    padding-inline-start:1.625em
}
